import {Observable, Subscription, timer} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Select, Store} from '@ngxs/store';
import {CleanByIndex} from '../messages.actions';
import {Message, MessagesState} from '../messages.state';


@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  animations: [
    trigger('displayHideTrigger', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.5s', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('0.5s', style({opacity: 0}))
      ])
    ])
  ],
})
export class MessagePopupComponent implements OnInit, OnDestroy {

  @Select(MessagesState.getMessages) messages$: Observable<Message[]>;

  messageSubscription: Subscription;
  timerSubscription: Subscription;

  constructor(private store: Store) {
  }

  ngOnInit() {
    // Each message is displayed for 3s.
    // In case of multiple messages, display them one after another.
    this.messageSubscription = this.messages$.subscribe(messages => {
      if (messages.length && !this.timerSubscription) {
        this.timerSubscription = timer(3000).subscribe(() => {
          this.timerSubscription = null;
          this.store.dispatch(new CleanByIndex(0));
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = null;
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }
}
