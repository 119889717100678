import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {DebugMessage, ErrorMessage, InfoMessage, SuccessMessage, WarrningMessage} from './messages.actions';

@Injectable()
export class MessagesService {

  constructor(private store: Store) { }

  debug(text: string): Observable<any> {
    return this.store.dispatch(new DebugMessage(text));
  }

  info(text: string): Observable<any> {
    return this.store.dispatch(new InfoMessage(text));
  }

  success(text: string): Observable<any> {
    return this.store.dispatch(new SuccessMessage(text));
  }

  warning(text: string): Observable<any> {
    return this.store.dispatch(new WarrningMessage(text));
  }

  error(text: string): Observable<any> {
    return this.store.dispatch(new ErrorMessage(text));
  }
}
