import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MessagesState } from './messages.state';
import { AlertComponent } from './alert/alert.component';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MessageTypePipe } from './message-type.pipe';
import {MessagesService} from './messages.service';
import { MessagePopupComponent } from './message-popup/message-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NgxsModule.forFeature([
      MessagesState,
    ]),
    MatButtonModule,
  ],
  declarations: [AlertComponent, MessageTypePipe, MessagePopupComponent],
  exports: [AlertComponent, MessagePopupComponent],
  providers: [
    MessagesService
  ]
})
export class MessagesModule { }
