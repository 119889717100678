<div #anchor class="anchor"></div>
@for (message of messages$ | async; track message; let i = $index) {
  <div
    class="message d-flex flex-row justify-content-center align-items-center"
    [ngClass]="{'message--floating': isFloating}"
    >
    <div class="d-flex flex-row align-items-center message--container message--{{ message.type}}" @fadeIn>
      <div class="message--icon flex-grow-0">
        @if (message.type === 'error') {
          <img src="/assets/images/invalid-input-icon.svg" alt="">
        } @else {
          <img src="/assets/images/valid-input-icon.svg" alt="">
        }
      </div>
      <div class="message--text flex-grow-1">{{ message.text}}</div>
      <div class="message--close flex-grow-0" (click)="clear(i)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#212429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#212429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
}
