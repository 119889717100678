import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {CleanByIndex} from '../messages.actions';
import {MessagesState} from '../messages.state';
import {WINDOW} from '../../universal/window/window.service';
import {FADE_IN} from '../../shared/animations';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rt-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FADE_IN]
})
export class AlertComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store,
  ) {
  }

  private subscription = new Subscription();

  public isFloating = false;
  @Select(MessagesState.getMessages) messages$: Observable<any>;

  @ViewChild('anchor', {static: true})
  anchor: ElementRef;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.toggleFloating();
  }

  ngOnInit() {
    this.subscription.add(
      this.messages$
        .pipe(filter(data => data && data.length))
        .subscribe(() => this.toggleFloating()),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleFloating() {
    const currentScroll = this.window.pageYOffset;
    const anchorPosition = this.anchor.nativeElement.offsetTop;

    if (currentScroll > anchorPosition) {
      this.isFloating = true;
    } else {
      this.isFloating = false;
    }
  }

  clear(index) {
    this.store.dispatch(new CleanByIndex(index));
  }

}
